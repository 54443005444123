<template>
    <div>
        <v-data-table :items="items" :headers="headers">
            <template v-slot:item.nombre="{ item }">
                <v-icon color="green" v-show="!!finish" left>check_circle</v-icon> {{ item.nombre }}
            </template>
            <template v-slot:item.fecha_evaluacion="{ item }">
                {{ item.fecha_evaluacion | moment('L') }}
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    props: ['items', 'finish'],
    data: () => ({
        headers: [
            { text: "Nombre", value: "nombre" },
            { text: "Descripción", value: "descripcion" },
            { text: "Finalizado el", value: "fecha_evaluacion" }
        ]
    })
}
</script>