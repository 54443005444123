<template>
    <v-row>
        <v-col :class="'flex-grow-0 flex-shrink-1 my-auto ' + ($vuetify.breakpoint.mdAndDown ? 'text-center' : 'text-left')">
            <v-progress-circular :size="size || 120" width="15" :value="value" :rotate="-90" :color="color">
                <span class="headline">{{ (value / 100.0) | decimal_percent }}</span>
            </v-progress-circular>
        </v-col>
        <v-col align="left" class="flex-grow-1 flex-shrink-0 my-auto">
            <slot></slot>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: ['value', 'color', 'size']
}
</script>