<template>
    <v-card outlined flat>
        <v-card-text v-if="!!data" class="pa-0">
            <v-row class="ma-0">
                <v-col class="flex-shrink-0 flex-grow-1">
                    <span class="d-block subtitle-1 font-weight-bold text--primary">{{ data.nombre }}</span>
                    <span class="d-block subtitle-2">{{ data.fecha_creacion | moment("LL") }}</span>
                </v-col>
                <v-col class="flex-shrink-0 flex-grow-1 ma-n1" align="end">
                    <v-btn large icon depressed class="subtitle-2 ma-1" :to="{ name: 'dashboard_eval_edit', params: { id: data.id }}" v-if="editable">
                        <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn large icon depressed dark color="red" class="subtitle-2 ma-1" @click="emit_delete(data)" v-if="editable">
                        <v-icon>close</v-icon>
                    </v-btn>
                    <v-btn large depressed class="subtitle-2 ma-1" :to="{ name: 'dashboard_eval_run', params: { id: data.id }}" color="secondary" dark v-if="rendible">
                        <v-icon left>play_arrow</v-icon>
                        Rendir
                    </v-btn>
                </v-col>
                <v-col cols="12" class="py-0">
                    <v-chip dark color="indigo" label v-if="data.is_grupal">
                        <v-icon left>people</v-icon>Grupal
                    </v-chip>
                    <span class="d-block subtitle-1 pb-2">{{ data.descripcion }}</span>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: ['rendible', 'editable', 'data'],
    methods: {
        emit_delete(data) {
            this.$emit('delete-item', data);
        }
    }
}
</script>