<template>
    <v-data-iterator :items="items" hide-default-footer :items-per-page.sync="pagination.item_page" :page="pagination.page">
        <template v-slot:item="{ item }">
            <EvaluacionListItem class="mb-2"
                :data="item" 
                :editable="editable" 
                :rendible="rendible"
            />
        </template>
        <template v-slot:no-data>
            <EmptyCard icon="inbox" text="Sin evaluaciones generales que dar" />
        </template>
        <template v-slot:footer>
            <v-pagination v-model="pagination.page" :length="pagination_length" />
        </template>
    </v-data-iterator>
</template>

<script>
import EvaluacionListItem from '@/educap/components/EvaluacionListItem'
import EmptyCard from '@/shared/components/EmptyCard'

export default {
    props: ['items', 'editable', 'rendible'],
    components: {
        EvaluacionListItem,
        EmptyCard
    },
    computed: {
        pagination_length() {
            if(!this.items) return 1;
            if(this.items.length < 1) return 1;
            return Math.round(this.items.length / this.pagination.item_page);
        }
    },
    data: () => ({
        pagination: {
            page: 1,
            item_page: 10
        }
    })
}
</script>