<template>
    <v-card outlined>
        <v-card-text class="pa-0">
            <v-row class="ma-0">
                <v-col cols="12">
                    <v-row no-gutters>
                        <!-- titulo -->
                        <v-col class="flex-grow-1 flex-shrink-0">
                            <h2 class="text-decoration-none text--primary d-block font-weight-regular">
                                <v-icon color="red" left v-if="data.is_valido !== undefined && !data.is_valido">error_outline</v-icon>
                                <v-icon left color="secondary">folder</v-icon>
                                <router-link v-if="!readonly" :to="{ name: 'dashboard_cap_item', params: { id: data.id }}">{{ data?.nombre || "Sin titulo" }}</router-link>
                                <template v-else>{{ data?.nombre || "Sin titulo" }}</template>
                            </h2>
                            <span class="subtitle-1">
                                {{ data.empresa?.nombre }}, {{ data.gerencia?.nombre }}
                            </span>
                            <!-- evaluador -->
                            <div class="d-block ma-n1" v-if="!!data">
                                <v-chip v-if="data.is_valido !== undefined && !data.is_valido" dark color="red" label>Curso caducado</v-chip>
                                <v-chip class="ma-1" label dark color="primary" v-if="!!data.autor && admin">
                                    <v-icon left>account_circle</v-icon>
                                    <template v-if="typeof(data.autor) === 'string'">{{ data.autor }}</template>
                                    <template v-else>{{ data.autor.first_name + ' ' + data.autor.last_name }}</template>
                                </v-chip>
                                <v-chip class="ma-1" label dark color="primary" v-if="!!data.evaluador">
                                    <v-icon left>account_circle</v-icon>
                                    {{ data.evaluador.first_name + ' ' + data.evaluador.last_name }}
                                </v-chip>
                            </div>
                        </v-col>
                        <!-- acciones -->
                        <v-col class="flex-shrink-0 flex-grow-1 ma-n1" align="right" v-if="!readonly">
                            <v-btn color="secondary" depressed class="subtitle-2 ma-1" :to="{ name: 'dashboard_cap_item', params: { id: data.id }}">
                                <v-icon left>chevron_right</v-icon>Entrar
                            </v-btn>
                            <v-btn icon color="primary" v-if="edit" depressed class="subtitle-2 ma-1" :to="{ name: 'dashboard_cap_edit', params: { id: data.id }}">
                                <v-icon>edit</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" v-if="!!data.descripcion" class="ma-0 py-0">
                    <span class="body text--primary">{{ data.descripcion }}</span>
                </v-col>
                <v-col cols="12">
                    <v-label>Temáticas</v-label>
                    <div class="d-block ma-n1">
                        <v-chip label v-for="(cat, i) in data.categorias" :key="i" class="ma-1">
                            {{ cat }}
                        </v-chip>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>

export default {
    props: {
        data: { type: Object, default: null },
        to: { type: Object, default: null },
        readonly: { type: Boolean, default: false },
        edit: { type: Boolean, default: false },
        admin: { type: Boolean, default: false },
        caducado: { type: Boolean, default: false }
    },
    computed: {
        autor() {
            if(!this.data || !this.data.autor) return "Autor Desconocido";
            return this.data.autor;
        }
    }
}
</script>