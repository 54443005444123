<template>
<div>
    <div v-if="error">
        <v-row>
            <v-col cols="12">
                <DashCard title="Curso no encontrado" title-icon="error_outline" class="text-center">
                    <v-icon class="d-block py-4" size="128" color="red">error</v-icon>
                    <v-label>El curso que buscas ya no existe o no está disponible.</v-label>
                </DashCard>
            </v-col>
        </v-row>
    </div>
    <div v-else>
        <v-row>
            <v-col cols="12" v-if="$parent.$parent.isin(['supers'])">
                <v-btn dark large depressed color="secondary" class="subtitle-2 ma-1" :to="{ name: 'dashboard_cap_edit', params: $route.params }">
                    <v-icon left>edit</v-icon>
                    Editar
                </v-btn>
                <v-btn dark large depressed color="red" class="subtitle-2 ma-1" @click="ask_delete_curso">
                    <v-icon left>close</v-icon>
                    Eliminar
                </v-btn>
                <v-btn dark large depressed color="secondary" class="subtitle-2 ma-1" :to="{ name: 'dashboard_eval_create', params: $route.params }">
                    <v-icon left>note_add</v-icon>
                    Crear evaluación
                </v-btn>
            </v-col>
            <v-col cols="12" sm="12" :md="$parent.$parent.isin('cap_evaluador') ? 4 : 6">
                <DashCard title="Acerca del curso" title-icon="assignment" body-class="pa-0">
                    <CursoItem class="ma-4" :data="index" readonly />
                </DashCard>
            </v-col>
            <v-col cols="12" sm="6" :md="$parent.$parent.isin('cap_evaluador') ? 4 : 6" v-if="$parent.$parent.isin(['cap_evaluador', 'supers'])">
                <DashCard title-icon="group" :title="$parent.$parent.isin('supers') ? 'Grupos que están en este curso' : 'Participantes'">
                    <EmptyCard v-if="index.grupos.length < 1" icon="people_outline" text="Sin datos disponibles" />
                    <v-card v-else outlined class="overflow-y-auto" height="300">
                        <v-list class="pa-0 ma-0" expand>
                            <v-list-group :value="true" prepend-icon="group" dense v-for="(grupo, g) in index.grupos" :key="g">
                                <template slot="activator">
                                    <v-list-item-title>{{ grupo.nombre }}</v-list-item-title>
                                </template>
                                <v-list-item class="pa-0 ma-0" two-line v-for="(perfil, i) in grupo.participantes" :key="i">
                                    <v-list-item-avatar class="pa-0 mx-2 my-0">
                                        <v-icon x-large color="green">person</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="pa-0 ma-0">
                                        <v-list-item-title>{{ perfil.first_name + ' ' + perfil.last_name }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ perfil.empresa.nombre }}, {{ perfil.gerencia.nombre }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-group>
                        </v-list>
                    </v-card>
                </DashCard>
            </v-col>
            <v-col cols="12" sm="6" :md="$parent.$parent.isin('cap_evaluador') ? 4 : 6">
                <DashCard title="Tu estado" title-icon="assignment" v-if="$parent.$parent.isin(['cap_evaluador', 'cap_participante'])">
                    <v-card outlined flat>
                        <v-row class="ma-0" no-gutters>
                            <v-col cols="12" v-if="!!eval_generales">
                                <HorizontalCircularProgress class="px-2" color="teal" :value="eval_generales.progreso * 100">
                                    <div class="text-left">
                                        <span class="d-block headline">Eval. Generales</span>
                                        <span class="d-block subtitle-1">
                                            <template v-if="eval_generales.total > 0">
                                                {{ eval_generales?.finalizadas.length }} eval. finalizada(s) de {{ eval_generales.total }}
                                            </template>
                                            <template v-else>
                                                No hay evaluaciones disponibles.
                                            </template>
                                        </span>
                                    </div>
                                </HorizontalCircularProgress>
                            </v-col>
                            <v-col cols="12" v-if="!!eval_grupales && $parent.$parent.isin(['cap_evaluador'])">
                                <HorizontalCircularProgress class="px-2" color="indigo" :value="eval_grupales.progreso * 100">
                                    <div class="text-left">
                                        <span class="d-block headline">Eval. Grupales</span>
                                        <span class="d-block subtitle-1 font-weight-bold">Solo disponibles para evaluadores</span>
                                        <span class="d-block subtitle-1">
                                            <template v-if="eval_grupales.total > 0">
                                                {{ eval_grupales?.finalizadas.length }} eval. finalizada(s) de {{ eval_grupales.total }}
                                            </template>
                                            <template v-else>
                                                No hay evaluaciones disponibles.
                                            </template>
                                        </span>
                                    </div>
                                </HorizontalCircularProgress>
                            </v-col>
                        </v-row>
                    </v-card>
                </DashCard>
            </v-col>
        </v-row>
        <v-row v-if="$parent.$parent.isin(['supers'])">
            <v-col cols="12" sm="6">
                <DashCard title="Evaluaciones generales" title-icon="book">
                    <EvaluacionIterator :items="eval_generales?.activas" 
                        :rendible="$parent.$parent.isin(['cap_evaluador', 'cap_participante'])" 
                        :editable="$parent.$parent.isin('supers')" />
                </DashCard>
            </v-col>
            <v-col cols="12" sm="6">
                <DashCard title="Evaluaciones grupales" title-icon="book">
                    <EvaluacionIterator :items="eval_grupales?.activas" 
                        :rendible="$parent.$parent.isin(['cap_evaluador', 'cap_participante'])" 
                        :editable="$parent.$parent.isin('supers')" />
                </DashCard>
            </v-col>
        </v-row>
        <v-row v-else-if="$parent.$parent.isin(['cap_participante', 'cap_evaluador'])">
            <v-col cols="12" sm="6">
                <DashCard title="En curso" title-icon="book" body-class="pa-0">
                    <v-tabs fixed-tabs>
                        <v-tab large class="subtitle-2">Generales</v-tab>
                        <v-tab large class="subtitle-2" v-if="$parent.$parent.isin(['cap_evaluador'])">Grupales</v-tab>
                        <v-tab-item>
                            <v-divider />
                            <EvaluacionIterator class="pa-4" :items="eval_generales?.activas" 
                                :rendible="$parent.$parent.isin(['cap_evaluador', 'cap_participante'])" 
                                :editable="$parent.$parent.isin('supers')" />
                        </v-tab-item>
                        <v-tab-item>
                            <v-divider />
                            <EvaluacionIterator class="pa-4" :items="eval_grupales?.activas" 
                                :rendible="$parent.$parent.isin(['cap_evaluador', 'cap_participante'])" 
                                :editable="$parent.$parent.isin('supers')" />
                        </v-tab-item>
                    </v-tabs>
                </DashCard>
            </v-col>
            <v-col cols="12" sm="6">
                <DashCard title="Finalizadas" title-icon="check" body-class="pa-0">
                    <v-tabs fixed-tabs>
                        <v-tab large class="subtitle-2">Generales</v-tab>
                        <v-tab v-if="$parent.$parent.isin(['cap_evaluador'])" large class="subtitle-2">Grupales</v-tab>
                        <v-tab-item>
                            <v-divider />
                            <EvaluacionTable :finish="true" :items="eval_generales?.finalizadas" />
                        </v-tab-item>
                        <v-tab-item>
                            <v-divider />
                            <EvaluacionTable :finish="true" :items="eval_grupales?.finalizadas" />
                        </v-tab-item>
                    </v-tabs>
                </DashCard>
            </v-col>
        </v-row>
        <Message ref="message" />
        <CommonDialog ref="delete_eval_dialog" @onClose="delete_eval" />
        <CommonDialog ref="delete_dialog" @onClose="delete_curso" />
    </div>
</div>
</template>

<script>
import DashCard from '@/shared/components/DashCard'
import CommonDialog from '@/shared/components/CommonDialog'
import Message from '@/shared/components/Message'
import CursoItem from '@/educap/components/CursoItem'
import EvaluacionIterator from '@/educap/components/EvaluacionIterator'
import EvaluacionTable from '@/educap/components/EvaluacionTable'
import HorizontalCircularProgress from '@/shared/components/HorizontalCircularProgress'
import EmptyCard from '@/shared/components/EmptyCard'
import { axiosHelper, messages } from '@/helpers'

export default {
    components: {
        DashCard,
        CommonDialog,
        Message,
        CursoItem,
        EvaluacionIterator,
        EvaluacionTable,
        EmptyCard,
        HorizontalCircularProgress
    },
    data: () => ({
        index: {},
        title: "Curso",
        loading: false,
        error: false
    }),
    computed: {
        eval() {
            return this.index.evaluaciones || {};
        },
        eval_generales() {
            return this.eval.generales;
        },
        eval_grupales() {
            return this.eval.grupales;
        }
    },
    methods: {
        ask_delete_curso() {
            this.$refs.delete_dialog.showDialog(messages.titles["question_delete_curso"], messages.texts["question_eliminar_curso"], "yes_no");
        },
        ask_delete_eval(item) {
            this.$refs.delete_eval_dialog.showDialog(messages.titles["question_delete_eval"], messages.texts["question_eliminar_eval"], "yes_no", item);
        },
        delete_curso(response) {
            if(response != 'yes') return;

            axiosHelper.del('edu/cursos/' + this.$route.params.id + '/')
                .then(response => {
                    this.$router.push({ name: 'dashboard_cap_list' });
                })
                .catch(response => {
                    this.$refs.message.show("Error", "No ha sido posible eliminar este curso.", "error");
                });
        },
        delete_eval(response, item) {
            if(response != 'yes') return;

            axiosHelper.del('edu/evaluaciones/' + item.id + '/')
                .then(response => {
                    this.$parent.$parent.reload_dashboard();
                })
                .catch(response => {
                    this.$refs.message.show("Error", "No ha sido posible eliminar la evaluación seleccionada.", "error");
                });
        }
    },
    mounted() {
        this.loading = true;
        
        axiosHelper.get('edu/cursos/' + this.$route.params.id + '/')
                .then(response => {
                    this.error = false;
                    this.index = response.data;
                    this.title = this.index.nombre;
                    this.$parent.$parent.set_title(this.title, "folder");
                    this.loading = false;
                })
                .catch(response => {
                    this.error = true;
                    this.loading = false;
                });

        this.$parent.$parent.set_title(this.title, "folder");
    }
}
</script>